import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';

export default class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.phoneInputRef = React.createRef();
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
  }

  componentDidMount() {
    const phoneInputField = this.phoneInputRef.current;

    if (this.telephoneInput || !phoneInputField) {
      return;
    }

    this.telephoneInput = intlTelInput(phoneInputField, {
      initialCountry: this.props.defaultCountry || 'de',
      countryOrder: ['de', 'gb', 'at', 'es', 'ch', 'nl', 'da', 'tr', 'uk'],
      formatAsYouType: false
    });
  }

  componentWillUnmount() {
    if (this.telephoneInput) {
      this.telephoneInput.destroy();
      this.telephoneInput = null;
    }
  }

  handlePhoneNumberChange() {
    this.props.onChange(this.telephoneInput.getNumber());
  }

  render() {
    return (
      <Input
        autoComplete="off"
        innerRef={this.phoneInputRef}
        onChange={this.handlePhoneNumberChange}
        invalid={this.props.invalid}
      />
    );
  }
}

PhoneNumberInput.propTypes = {
  defaultCountry: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool
};
