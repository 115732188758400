import $ from 'jquery';
import TomSelect from 'tom-select';

const scoreByMatchingString = (search) => (item) => {
  const term = search.replace(/\s+/g, '').toLowerCase();
  const text = item.text.replace(/\s+/g, '').toLowerCase();
  return text.includes(term) ? 1 : 0;
};

const alreadyTomselected = (element) => {
  if (element.classList.contains('tomselected')) {
    console.debug('Element was already tomselected.');
    console.debug(element);

    return true;
  }

  return false;
};

const updateTomSelectState = (element) => {
  if (element.hasAttribute('disabled') && !element.tomselect.isDisabled) {
    element.tomselect.disable();
  } else if (!element.hasAttribute('disabled') && element.tomselect.isDisabled) {
    element.tomselect.enable();
  }
};

const observeTomSelectDisabledAttribute = (element) => {
  const observer = new MutationObserver(() => updateTomSelectState(element));
  observer.observe(element, { attributes: true, attributeFilter: ['disabled'] });
};

const setupTomSelect = (element) => {
  if (alreadyTomselected(element)) {
    return null;
  }

  const tomSelect = new TomSelect(element, {
    maxOptions: null,
    score: scoreByMatchingString
  });

  updateTomSelectState(element);
  observeTomSelectDisabledAttribute(element);

  return tomSelect;
};

const setupTomSelectTagging = (element) => {
  if (alreadyTomselected(element)) {
    return null;
  }

  const tomSelect = new TomSelect(element, {
    plugins: {
      remove_button: {}
    },
    create: true,
    delimiter: ',',
    hidePlaceholder: true,
    maxOptions: null,
    maxItems: null,
    scoreByMatchingString
  });

  updateTomSelectState(element);
  observeTomSelectDisabledAttribute(element);

  return tomSelect;
};

const setupTomSelectAjax = (element, url) => {
  const tomSelect = new TomSelect(element, {
    maxOptions: null,
    valueField: 'value',
    labelField: 'label',
    searchField: 'label',
    load(query, callback) {
      const headers = new Headers({
	'Content-Type': 'application/json',
	'X-Api-Version': '1'
      });

      const queryURL = `${url}?term=${encodeURIComponent(query)}`;

      fetch(queryURL, { headers })
	.then(response => response.json())
	.then(json => {
	  callback(json.data);
	}).catch((error) => {
	  console.debug(error);

	  callback();
	});
    }
  });

  updateTomSelectState(element);
  observeTomSelectDisabledAttribute(element);

  return tomSelect;
};

const setupRecurrenceRulesFormButtons = () => {
  $(document).ready(() => {
    $('form').on('click', '.js-remove-row', (event) => {
      const row = event.target.closest('tr');

      row.remove();

      event.preventDefault();
    });

    $('form').on('click', '.js-all-day', (event) => {
      const row = event.target.closest('tr');
      const fromOffset = row.querySelector('.js-valid-from-offset');
      const untilOffset = row.querySelector('.js-valid-until-offset');

      fromOffset.tomselect.setValue('0');
      untilOffset.tomselect.setValue('86400');

      event.preventDefault();
    });

    $('form').on('click', '.js-copy', (event) => {
      document.querySelector('.js-add-row').click();

      const copiedRow = event.target.closest('tr');
      const copiedRowDayOfWeek = copiedRow.querySelector('.js-day-of-week').tomselect;
      const copiedRowFromOffset = copiedRow.querySelector('.js-valid-from-offset').tomselect;
      const copiedRowUntilOffset = copiedRow.querySelector('.js-valid-until-offset').tomselect;

      const lastRow = document.getElementById('recurrence_rulesTable').querySelector('tr:last-of-type');
      const lastRowDayOfWeek = lastRow.querySelector('.js-day-of-week').tomselect;
      const lastRowFromOffset = lastRow.querySelector('.js-valid-from-offset').tomselect;
      const lastRowUntilOffset = lastRow.querySelector('.js-valid-until-offset').tomselect;

      lastRowDayOfWeek.setValue(copiedRowDayOfWeek.getValue());
      lastRowFromOffset.setValue(copiedRowFromOffset.getValue());
      lastRowUntilOffset.setValue(copiedRowUntilOffset.getValue());

      event.preventDefault();
    });

    $('form').on('click', '.js-add-row', (event) => {
      const lastRow = document.querySelector('#recurrence_rulesTable').querySelector('tr:last-of-type');

      const time = Date.now();
      const regexp = new RegExp(event.target.dataset.id, 'g');
      const newRowHTML = event.target.dataset.fields.replace(regexp, time);
      const newRow = document.createElement('tr');
      newRow.innerHTML = newRowHTML;

      setupTomSelect(newRow.querySelector('.js-day-of-week'));
      setupTomSelect(newRow.querySelector('.js-valid-from-offset'));
      setupTomSelect(newRow.querySelector('.js-valid-until-offset'));

      lastRow.insertAdjacentElement('afterend', newRow);

      event.preventDefault();
    });
  });
};

export { setupTomSelect, setupTomSelectTagging, setupTomSelectAjax, setupRecurrenceRulesFormButtons };
