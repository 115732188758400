import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';

const ManagementProviders = {
  init() {
    this.handleTwilioSidChange();
  },

  handleTwilioSidChange() {
    const twilioSidField = document.querySelector('.js-twilio-messaging-services');
    twilioSidField?.addEventListener('change', this.handleSidUpdate.bind(this));
  },

  handleSidUpdate(event) {
    const selectedElement = event.target.selectedOptions[0];
    const { phoneNumber } = selectedElement?.dataset || {};
    const phoneInput = document.getElementById("provider_sms_support_number");

    const iti = intlTelInput.getInstance(phoneInput);

    if (phoneNumber && iti) {
      iti.setNumber(phoneNumber);
    } else {
      phoneInput.value = '';
      window.setTimeout(() => phoneInput.focus(), 100);
    }
  }
}

export default ManagementProviders;
