import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import loadData from '../../lib/bootstrapped_data';
import { setupTomSelect, setupTomSelectAjax } from '../../lib/form_helpers';
import HereMap from '../../components/here_maps/HereMap.jsx';
import Line from '../../components/here_maps/Line.jsx';

const AdministrationLines = {
  init() {
    this.renderMaps();
    this.enableFormButtons();
  },
  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    const lineData = loadData('line');
    const linesData = loadData('lines');

    let lines = [];

    if (linesData && linesData.data) {
      lines = linesData.data;
    } else if (lineData && lineData.data) {
      lines = [lineData.data];
    }
    if (!lines.length) return;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        {lines.map((line) => (
          <Line key={line.id} line={line} />
        ))}
      </HereMap>,

      mapEl.get(0)
    );
  },

  enableFormButtons() {
    $('form').on('click', '.js-remove-row', function removeRow(event) {
      const row = $(this).closest('tr');

      row.find('input[class=js-remove-flag]').val('1');
      row.hide();

      return event.preventDefault();
    });

    $('form').on('click', '.js-add-row', function addRow(event) {
      const container = $('.js-line-stops');
      const lastRow = container.find('tr').last();
      const lastTier = lastRow.find('.js-line-tier');
      const lastRelativeTime = lastRow.find('.js-line-relative-time');

      // Every row of line stops needs a unique id.
      const time = Date.now();
      const regexp = new RegExp($(this).data('id'), 'g');

      const newRow = $($(this).data('fields').replace(regexp, time));
      const autoCompleteStationWidget = newRow.find('.js-autocomplete-station-widget')[0];
      const lineStation = newRow.find('.js-line-station')[0];

      // Tiers are sequential.
      newRow.find('.js-line-tier').val(Number(lastTier.val() || 0) + 1);
      newRow.find('.js-line-relative-time').val(lastRelativeTime.val());

      setupTomSelect(newRow.find('.js-line-relative-time')[0]);

      if(lineStation) {
	setupTomSelect(lineStation);
      }

      if(autoCompleteStationWidget) {
	setupTomSelectAjax(autoCompleteStationWidget, autoCompleteStationWidget.dataset.selectAutocompletePath);
      }

      container.append(newRow);

      return event.preventDefault();
    });
  }
}

export default AdministrationLines;
