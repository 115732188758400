import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, pick, compact, omit, sortBy } from 'lodash';
import { Button } from 'reactstrap';
import Control from 'react-leaflet-control';
import { roundToNearestMinutes, addSeconds } from 'date-fns';
import PaymentMethodDropdown from './booking/PaymentMethodDropdown';
import UserSelect from './booking/UserSelect';
import PreBookingShortcutsModal from './booking/PreBookingShortcutsModal';
import PrebookingTime from './booking/PrebookingTime';
import PassengerNoteToDriver from './booking/PassengerNoteToDriver';
import RideDetails from './booking/RideDetails';
import StellwerkAPI from '../lib/stellwerk_api';
import Alert from './bootstrap/Alert';
import Card from './bootstrap/Card';
import { secondsToHuman, formattedTime, timeDiff } from '../lib/view_helpers';
import { buildRequestedPoint, rideDuration, cleanExistingWaypoint } from '../lib/booking_helpers';
import WaypointSwitcher from './booking/WaypointSwitcher';
import WaypointSelector from './booking/WaypointSelector';
import RideOptions from './booking/RideOptions';
import HereMap from './here_maps/HereMap';
import Station from './here_maps/markers/Station';
import SVGMarker from './here_maps/markers/SVGMarker';
import { latLngFromObj } from '../lib/map_helpers';
import pickupIcon from '../images/maps/svg_icons/pickup.svg';
import dropoffIcon from '../images/maps/svg_icons/dropoff.svg';
import Area from './here_maps/layers/Area';
import Ride from './here_maps/Ride';

const colors = {
  black: '#000',
  green: '#00e069',
  red: '#ff2b79'
};

export default class BookingWizard extends React.Component {
  constructor(props) {
    super(props);

    this.api = new StellwerkAPI(props.product.id);
    this.secondRefreshTimer = null;

    this.state = this.initialState(props);

    this.setDestination = this.setDestination.bind(this);
    this.setOrigin = this.setOrigin.bind(this);
    this.setRequestedTime = this.setRequestedTime.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setChosenPaymentMethod = this.setChosenPaymentMethod.bind(this);
    this.initiateStateFromClonedRide = this.initiateStateFromClonedRide.bind(this);
    this.switchFixedWaypoints = this.switchFixedWaypoints.bind(this);
    this.setOption = this.setOption.bind(this);
    this.setPassengers = this.setPassengers.bind(this);
    this.addPassenger = this.addPassenger.bind(this);
    this.setNearbyMatchingMode = this.setNearbyMatchingMode.bind(this);

    this.setPassengerNoteToDriver = this.setPassengerNoteToDriver.bind(this);

    this.setServedAreas = this.setServedAreas.bind(this);
    this.setUnservedAreas = this.setUnservedAreas.bind(this);
    this.lookupAreas = this.lookupAreas.bind(this);

    this.createRideInquiry = this.createRideInquiry.bind(this);
    this.bookRide = this.bookRide.bind(this);
    this.createRide = this.createRide.bind(this);
    this.pollRide = this.pollRide.bind(this);
    this.cancelRide = this.cancelRide.bind(this);

    this.resetWizard = this.resetWizard.bind(this);
    this.bookAgain = this.bookAgain.bind(this);
    this.bookReturn = this.bookReturn.bind(this);

    this.togglePrebookingModal = this.togglePrebookingModal.bind(this);
    this.toggleStationVisibility = this.toggleStationVisibility.bind(this);
  }

  // LIFECYCLE METHODS ---------------------------------------------------------

  componentDidMount() {
    if (isEmpty(this.props.cloningRide)) {
      if (this.props.product.requires_fixed_station) {
        // Setting up a default value
        this.switchFixedWaypoints('origin');
      }
    } else {
      this.showSpinner(window.locales.BookingWizard.spinners.cloningRide);
      const self = this;

      self.initiateStateFromClonedRide();
      self.hideSpinner();
    }

    this.createRideInquiry();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isNil(this.state.userId) && this.state.userId !== prevState.userId) {
      this.fetchUserBootstrap(this.state.userId);
    }

    if (this.state.requestedTime !== prevState.requestedTime) {
      this.createRideInquiry();
    }
  }

  componentWillUnmount() {
    this.endTicker();
  }

  getDefaultValue(dataType) {
    switch(dataType) {
      case 'boolean':
        return false;
      case 'string':
        return "";
      case 'integer':
        return 0;
      default:
        return null;
    }
  }

  // STATE MANAGEMENT ----------------------------------------------------------
  getNewUninitializedPassenger() {
    return {
      type: this.getDefaultPassengerType(),
      first_name: null,
      last_name: null,
      options: this.getInitialPassengerOptions()
    };
  }

  getInitialPassengerOptions() {
    if (!this.props.product.passenger_options) { return {}; }


    return  this.props.product.passenger_options.map(option =>
      ({
        slug: option.slug,
        value: this.getDefaultValue(option.data_type)
      })
    );
  }

  getDefaultPassengerType() {
    if (!this.props.product.passenger_types[0]) { return ''; }

    return this.props.product.passenger_types[0].slug;
  }

  getInitialRideOptionValues() {
    if(!this.props.product.product_ride_options) { return []; }

    return this.props.product.product_ride_options.map(option =>
      ({
        slug: option.slug,
        value: this.getDefaultValue(option.data_type)
      })
    );
  }

  setUser(userId) {
    this.setState({ userId });
  }

  setOrigin(origin) {
    this.setState({ origin });
  }

  setDestination(destination) {
    this.setState({ destination });
  }

  setRequestedTime(requestedTime, requestedTimeForWaypoint) {
    if (!this.props.product.ad_hoc_bookable && !requestedTime) {
      return;
    }

    if (!requestedTimeForWaypoint) {
      requestedTimeForWaypoint = this.state.requestedTimeForWaypoint;
    }
    this.setState({ requestedTime, requestedTimeForWaypoint });
  }

  setOption(slug, value) {
    const { options } = this.state;
    const option = options.find(( opt ) => opt.slug === slug);

    if (option === undefined) { return }

    option.value = value
    this.setState({ options });
  }

  setNearbyMatchingMode(waypointType, value) {
    const { nearbyMatchingMode } = this.state;

    nearbyMatchingMode[waypointType] = value;

    this.setState({ nearbyMatchingMode });
  }

  setPassengerNoteToDriver(note) {
    this.setState({ passengerNoteToDriver: note });
  }

  setPassengers(index, passengerData) {
    const { passengers } = this.state;
    passengers[index] = passengerData;

    this.setState({ passengers: compact(passengers) });
  }

  setErrors(errors) {
    this.setState({ errors });
  }

  setChosenPaymentMethod(chosenPaymentMethod) {
    this.setState({ chosenPaymentMethod });
  }

  setAssistances(assistances) {
    const assistanceErrors = [];
    const assistanceWarnings = [];
    assistances.forEach(a => (a.error_code ? assistanceErrors : assistanceWarnings).push(a.text));

    // update state with the assistances objects, additionally with errors/warnings if existent
    const stateUpdate = { assistances };
    if (assistanceErrors.length > 0) {
      stateUpdate.errors = (this.state.errors || []).concat(assistanceErrors);
    }
    if (assistanceWarnings.length > 0) {
      stateUpdate.warnings = (this.state.warnings || []).concat(assistanceWarnings);
    }

    this.setState(stateUpdate);
  }

  setServedAreas(servedAreaIds) {
    const servedAreas = this.lookupAreas(servedAreaIds, 'served_area');

    this.setState({ servedAreas });
  }

  setUnservedAreas(unservedAreaIds) {
    const unservedAreas = this.lookupAreas(unservedAreaIds, 'unserved_area');

    this.setState({ unservedAreas });
  }

  lookupAreas(ids, areaType) {
    const { areas } = this.props;

    return areas.filter(area => area.area_type === areaType && ids.includes(area.id));
  }

  addPassenger() {
    const { passengers } = this.state;

    passengers.push(this.getNewUninitializedPassenger());

    this.setState({ passengers });
  }

  switchFixedWaypoints(fixedWaypoint) {
    this.setState({
      fixedWaypoint,
      origin: this.state.destination,
      destination: this.state.origin
    });
    
    const otherWaypointMatchingMode = this.state.nearbyMatchingMode[fixedWaypoint]
    const otherWaypoint = fixedWaypoint === 'origin' ? 'destination' : 'origin'
    this.setNearbyMatchingMode(fixedWaypoint, false);
    this.setNearbyMatchingMode(otherWaypoint, otherWaypointMatchingMode);
  }

  initialRequestedBookingTime(props) {
    const notAdhocBookable = !props.product.ad_hoc_bookable && props.product.prebookable;
    const prebookableAsDefault =
      props.product.prebookable && !!props.product.booking_wizard_settings.prebooking_preselected;

    if (notAdhocBookable || prebookableAsDefault) {
      const baseTime = roundToNearestMinutes(
        addSeconds(new Date(), props.product.prebooking_threshold.min),
        {
          nearestTo: 5
        }
      );

      return baseTime;
    }

    return null;
  }

  initialPaymentMethods(props) {
    const singletonPaymentMethodTypes = ['cash', 'pos_payment', 'service_credits'];
    // only let the singleton payment methods through here. The other payment methods
    // will be added once we fetched the user

    return props.product.provider.ride_payment_method_types
      .filter((type) => singletonPaymentMethodTypes.includes(type))
      .map((type) => ({ payment_method_type: type }));
  }

  initialState(props) {
    return {
      origin: null,
      destination: null,
      requestedTimeForWaypoint: 'origin',
      passengers: [this.getNewUninitializedPassenger()],
      options: this.getInitialRideOptionValues(),
      fixedWaypoint: null,
      requestedTime: this.initialRequestedBookingTime(props),
      userId: null,
      ride: null,
      errors: null,
      warnings: null,
      spinnerMessage: null,
      currentTime: new Date(),
      paymentMethods: this.initialPaymentMethods(props),
      chosenPaymentMethod: null,
      previouslyRequestedPoints: [],
      showPrebookingModal: false,
      showStations: window.localStorage.showStations !== 'false',
      nearbyMatchingMode: {
        origin: true,
        destination: true
      },
      servedAreas: [],
      unservedAreas: [],
      passengerNoteToDriver: ''
    };
  }

  resetWizard() {
    this.setState(this.initialState(this.props));
  }

  bookAgain() {
    this.setState({
      ride: null,
      errors: null,
      warnings: null,
      spinnerMessage: null
    });

    if (this.productSupportsPrebookingAssistance() && this.state.requestedTime) {
      this.togglePrebookingModal();
    }
  }

  bookReturn() {
    const { destination: newOrigin, origin: newDestination } = this.state;
    this.setState({
      ride: null,
      origin: newOrigin,
      destination: newDestination,
      fixedWaypoint: this.fixedOriginOrDestination(newOrigin),
      errors: null,
      warnings: null
    });

    if (this.productSupportsPrebookingAssistance() && this.state.requestedTime) {
      this.togglePrebookingModal();
    }
  }

  togglePrebookingModal() {
    this.setState(prevState => ({ showPrebookingModal: !prevState.showPrebookingModal }));
  }

  toggleStationVisibility() {
    this.setState((prevState) => {
      const newValue = !prevState.showStations;
      window.localStorage.setItem('showStations', newValue.toString());
      return { showStations: newValue };
    });
  }

  // SPECIAL CLONING RIDE BEHAVIOR ---------------------------------------------

  initiateStateFromClonedRide() {
    const { cloningRide, cloningDirection } = this.props;
    let origin = null;
    let destination = null;

    if (cloningDirection === 'return') {
      origin = cleanExistingWaypoint(cloningRide.destination);
      destination = cleanExistingWaypoint(cloningRide.origin);
    } else {
      origin = cleanExistingWaypoint(cloningRide.origin);
      destination = cleanExistingWaypoint(cloningRide.destination);
    }

    const fixedWaypoint = this.fixedOriginOrDestination(origin);

    this.setNearbyMatchingMode(fixedWaypoint, false);

    this.setState({
      userId: cloningRide.user.id,
      origin,
      destination,
      fixedWaypoint,
      chosenPaymentMethod: cloningRide.payment_method || null,
      passengers: cloningRide.passengers.map((passenger) => {
        const { options, ...passengerWithoutOptions } = passenger;
        const optionsWithSlug = options.map((passengerOption) => (
          {
            slug: passengerOption.slug,
            value: passengerOption.value,
            type: passengerOption.type
          }
        ));

        return {
          ...passengerWithoutOptions,
          options: optionsWithSlug
        }
      })
    });
    // only set values that are on the cloned ride and leave default values that are not on the cloned ride
    const clonedOptions = Object.entries(cloningRide.options);

    clonedOptions.forEach(option => {
      this.setOption(option[0], option[1]);
    });
  }

  fixedOriginOrDestination(origin) {
    if (!this.productRequiresFixedStation()) {
      return null;
    }

    const fixedStations = this.props.product.fixed_stations;
    const originStationId = origin.station_id || origin.id;

    if (fixedStations.some((station) => station.id === originStationId)) {
      return 'origin';
    }

    return 'destination';
  }

  // TIMER CONTROLS ------------------------------------------------------------

  startTicker() {
    this.secondRefreshTimer = setInterval(() => {
      this.setState({
        currentTime: new Date()
      });
    }, 1000);
  }

  endTicker() {
    if (this.secondRefreshTimer) window.clearInterval(this.secondRefreshTimer);
  }

  // LOADING INDICATOR CONTROLS ------------------------------------------------

  showSpinner(spinnerMessage) {
    this.setState({ spinnerMessage });
  }

  hideSpinner() {
    this.setState({ spinnerMessage: null });
  }

  // PREDICATES ----------------------------------------------------------------

  rideNeedsPaymentMethod() {
    return this.state.paymentMethods.length > 0;
  }

  rideIsCreateable() {
    return (
      !!this.state.origin &&
      !!this.state.destination &&
      !!this.state.passengers.length > 0 &&
      !!this.state.userId &&
      (!this.state.ride || this.state.ride.state === 'cancelled') &&
      (this.rideNeedsPaymentMethod() ? !!this.state.chosenPaymentMethod : true) &&
      (this.productRequiresPassengerNames() ? this.allPassengersHavePublicTransportTicketOrNameProvided() : true)
    );
  }

  rideIsBookable() {
    return this.state.ride && this.state.ride.state === 'ready';
  }

  productSupportsPassengerNoteToDriver() {
    return this.props.product.supports_passenger_note_to_driver;
  }

  productRequiresFixedStation() {
    return this.props.product.requires_fixed_station && this.props.product.fixed_stations.length > 0;
  }

  productSupportsPrebookingAssistance() {
    return this.props.product.prebookable && this.props.product.supports_prebooking_ui_assistance;
  }

  productRequiresPassengerNames() {
    return this.props.product.requires_passenger_names_or_ticket;
  }

  allPassengersHavePublicTransportTicketOrNameProvided() {
    return this.state.passengers.every(
      (passenger) =>
        isNil(passenger) ||
        passenger.options.find(o => o.slug === 'public_transport_ticket' && o.value === true) ||
        (!isEmpty(passenger.first_name) && !isEmpty(passenger.last_name))
    );
  }

  originParams() {
    if (this.state.nearbyMatchingMode.origin === true) {
      return omit(this.state.origin, 'type', 'id', 'station_id')
    }

    return this.state.origin;
  }

  destinationParams() {
    if (this.state.nearbyMatchingMode.destination === true) {
      return omit(this.state.destination, 'type', 'id', 'station_id')
    }

    return this.state.destination;
  }

  // HEAVY API HITTING OPERATIONS ----------------------------------------------
  createRideInquiry() {
    const rideInquiryData = {
      user_id: this.state.userId,
      passengers: compact(this.state.passengers),
      origin: buildRequestedPoint({
        type: 'origin',
        params: this.originParams(),
        requestedTimeForWaypoint: this.state.requestedTimeForWaypoint,
        requestedTime: this.state.requestedTime
      }),
      destination: buildRequestedPoint({
        type: 'destination',
        params: this.destinationParams(),
        requestedTimeForWaypoint: this.state.requestedTimeForWaypoint,
        requestedTime: this.state.requestedTime
      }),
      options: this.state.options
    };

    this.setState({
      errors: null,
      warnings: null
    });
    this.showSpinner(window.locales.BookingWizard.spinners.inquiring);

    this.api
      .createRideInquiry(rideInquiryData)
      .done((rideInquiryResult) => {
        this.setServedAreas(rideInquiryResult.data.served_area_ids);
        this.setUnservedAreas(rideInquiryResult.data.unserved_area_ids);
        this.setAssistances(rideInquiryResult.data.assistances);

        this.hideSpinner();
      })
      .fail((error) => {
        if (error.status === 422) {
          this.setState({
            spinnerMessage: null,
            errors: error.responseJSON.api_errors.map((item) => item.message)
          });
        }
      });
  }

  createRide() {
    const rideData = {
      user_id: this.state.userId,
      passengers: compact(this.state.passengers),
      origin: buildRequestedPoint({
        type: 'origin',
        params: this.originParams(),
        requestedTimeForWaypoint: this.state.requestedTimeForWaypoint,
        requestedTime: this.state.requestedTime
      }),
      destination: buildRequestedPoint({
        type: 'destination',
        params: this.destinationParams(),
        requestedTimeForWaypoint: this.state.requestedTimeForWaypoint,
        requestedTime: this.state.requestedTime
      }),
      options: this.state.options,
      passenger_note_to_driver: this.state.passengerNoteToDriver
    };

    this.setErrors(null);
    this.showSpinner(window.locales.BookingWizard.spinners.searching);

    this.api
      .createRide(rideData)
      .done((rideResult) => {
        this.setState({ ride: rideResult.data });
        window.setTimeout(this.pollRide, 2000);
      })
      .fail((error) => {
        if (error.status === 422) {
          this.setState({
            spinnerMessage: null,
            errors: error.responseJSON.api_errors.map((item) => item.message)
          });
        }
      });
  }

  bookRide() {
    if (!this.state.ride || this.state.ride.state !== 'ready') return;

    this.setErrors(null);
    this.showSpinner(window.locales.BookingWizard.spinners.waiting_for_driver);

    const bookingData = {};

    if (this.state.chosenPaymentMethod) {
      bookingData.payment_method = pick(this.state.chosenPaymentMethod, 'payment_method_type', 'id');
    }

    this.api
      .bookRide(this.state.ride.id, bookingData)
      .done(() => {
        this.pollRide();
      })
      .fail((error) => {
        if (error.status === 422) {
          this.setState({
            spinnerMessage: null,
            errors: error.responseJSON.api_errors.map((item) => item.message)
          });
        }
      });
  }

  cancelRide() {
    if (!this.state.ride) return;

    this.setErrors(null);

    if (this.state.ride.state === 'cancelled') {
      // The ride was already cancelled by the system (no vehicle available, etc)
      this.setState({ ride: null });
    } else {
      // The ride is in any other state
      let cancellationConfirmed = true;

      if (this.state.ride.state === 'passenger_accepted' || this.state.ride.state === 'driver_accepted') {
        cancellationConfirmed = window.confirm(window.locales.BookingWizard.message.cancelConfirmation);
      }

      if (cancellationConfirmed) {
        this.api.cancelRide(this.state.ride.id).done(() => {
          this.setState({
            spinnerMessage: null,
            ride: null
          });
        });
      }
    }
  }

  pollRide() {
    if (!this.state.ride) return;

    if (!this.state.spinnerMessage) {
      this.showSpinner(window.locales.BookingWizard.spinners.refreshing);
    }

    this.api.getRide(this.state.ride.id).done((rideResult) => {
      this.setState({ ride: rideResult.data });

      // When the ride was cancelled
      if (rideResult.data.state === 'cancelled') {
        const cancellationReasons = [rideResult.data.cancellation_reason_translated];

        this.setErrors(cancellationReasons);
      }

      // When the ride was accepted by the driver
      if (rideResult.data.state === 'driver_accepted') {
        this.setState({
          spinnerMessage: null,
          errors: null
        });

        if (!rideResult.data.pickup) {
          window.setTimeout(this.pollRide, 2000);
        }
      }

      if (rideResult.data.state === 'searching' || rideResult.data.state === 'passenger_accepted') {
        // Searching for a ride, or waiting for driver, reload every 2s,
        // Stop the ticker, since the customer does not need to do anything anymore
        window.setTimeout(this.pollRide, 2000);
        this.endTicker();
      } else if (rideResult.data.state === 'ready') {
        // Offered Ride to the passenger, reload every 10s to wait for timeout,
        // start the ticker to show time left countdown
        this.startTicker();
        this.hideSpinner();
        window.setTimeout(this.pollRide, 10000);
      } else {
        this.hideSpinner();
      }
    });
  }

  fetchUserBootstrap(userId) {
    this.api
      .fetchUserBootstrap(userId)
      .done((bootstrapData) => {
        const {
          payment_methods: bootstrapPaymentMethods,
          requested_points: previouslyRequestedPoints,
          last_ride_options: lastRideOptions,
          outstanding_payments: outstandingPayments,
          first_name: firstName,
          last_name: lastName,
          warnings
        } = bootstrapData.data;
        const { cloningRide, product } = this.props;
        const { passengers } = this.state;

        const paymentMethods = bootstrapPaymentMethods.map((paymentMethod) =>
          pick(paymentMethod, 'payment_method_type', 'details', 'id')
        );

        // Now append the payment methods that have already been there, set the state to the new paymentMethods and
        // select the first payment method of those in the state or skip that if cloning a ride
        paymentMethods.push(...this.initialPaymentMethods(this.props));

        const newState = { paymentMethods, previouslyRequestedPoints, warnings: [] };

        if (isEmpty(cloningRide.payment_method)) {
          const chosenPaymentMethod = paymentMethods[0];
          newState.chosenPaymentMethod = chosenPaymentMethod;
        }

        // when we are cloning a ride, we just copy over all the data from the cloned
        // ride and don't need to do anything here
        if (isEmpty(cloningRide)) {
          // when we are not cloning a ride and it is activated that the previous ride
          // settings should be copied over, copy over the ride settings
          if (!!product.booking_wizard_settings.use_last_ride_options && !isEmpty(lastRideOptions)) {
            newState.passengers = lastRideOptions.passengers;

            Object.entries(lastRideOptions.options).forEach((option) => {
              this.setOption(option[0], option[1]);
            })
          } else {
            newState.passengers = passengers;
          }

          if( !isEmpty(warnings)) {
            newState.warnings = warnings.map((item) => item)
          }

          // now let's make sure we have a name set on the first passenger
          if (!isEmpty(newState.passengers)) {
            newState.passengers[0].first_name = newState.passengers[0].first_name || firstName;
            newState.passengers[0].last_name = newState.passengers[0].last_name || lastName;
          }

        }
        if (outstandingPayments && outstandingPayments.has_outstanding_payments === true){
          const number = outstandingPayments.outstanding_total_amount/100.0;
          let langFormat = document.querySelector('html').getAttribute('lang')
          if (!langFormat) { langFormat = 'en-BZ' }

          const formattedAmount = new Intl
            .NumberFormat(langFormat, { style: 'currency', currency: outstandingPayments.currency })
            .format(number);

          const newWarning = window.locales.BookingWizard.warnings
            .outstanding_payments
            .replace('%{rides}', outstandingPayments.num_of_outstanding_payments)
            .replace('%{amount}',  formattedAmount);

          newState.warnings.push(newWarning);
        }

         this.setState(newState);
      })
      .fail((error) => {
        if (error.status === 422) {
          this.setState({
            spinnerMessage: null,
            errors: error.responseJSON.api_errors.map((item) => item.message)
          });
        }
      });
  }

  // RENDER METHODS ------------------------------------------------------------

  destinationMarker() {
    if (!this.state.destination || this.state.ride) return null;
    return (
      <SVGMarker
        icon={dropoffIcon}
        color={colors.red}
        position={latLngFromObj(this.state.destination)}
        markerType="destination"
        size={[31, 45]}
        anchor={[15, 45]}
      />
    );
  }

  originMarker() {
    if (!this.state.origin || this.state.ride) return null;

    return (
      <SVGMarker
        icon={pickupIcon}
        color={colors.green}
        position={latLngFromObj(this.state.origin)}
        markerType="origin"
        size={[31, 45]}
        anchor={[15, 45]}
      />
    );
  }

  timeLeftToBook() {
    if (!this.state.ride || (this.state.ride && this.state.ride.state !== 'ready')) {
      return null;
    }

    const secondsLeft = timeDiff(this.state.currentTime, this.state.ride.valid_for_passenger_until);

    if (secondsLeft < 0) return null;

    return (
      <span className="badge badge-warning">
        {`${window.locales.BookingWizard.spinners.remaining}: ${secondsToHuman(secondsLeft)}`}
      </span>
    );
  }

  nextOptionButtons() {
    if (!this.state.ride || (this.state.ride && this.state.ride.state !== 'driver_accepted')) {
      return null;
    }

    return (
      <>
        <Button color="primary" size="lg" onClick={this.resetWizard}>
          {window.locales.BookingWizard.buttons.newRide}
        </Button>
        <Button color="success" size="lg" className="pl-3" onClick={this.bookAgain}>
          {window.locales.BookingWizard.buttons.bookAgain}
        </Button>
        <Button color="success" size="lg" onClick={this.bookReturn}>
          {window.locales.BookingWizard.buttons.bookReturn}
        </Button>
      </>
    );
  }

  createRideButton() {
    return (
      <button className="btn btn-primary btn-lg" type="button" onClick={this.createRide} disabled={!this.rideIsCreateable()}>
        {window.locales.BookingWizard.buttons.create}
      </button>
    );
  }

  bookRideButton() {
    if (!this.rideIsBookable()) return null;

    return (
      <button className="btn btn-success btn-lg" type="button" onClick={this.bookRide}>
        {window.locales.BookingWizard.buttons.book}
      </button>
    );
  }

  cancelRideButton() {
    if (!this.state.ride) return null;

    return (
      <button className="btn btn-danger btn-lg" type="button" onClick={this.cancelRide}>
        {window.locales.BookingWizard.buttons.cancel}
      </button>
    );
  }

  renderStationVisibilityToggle() {
    if (!this.props.stations || this.props.stations.length === 0) {
      return null;
    }

    return (
      <Control position="bottomleft" className="leaflet-control-layers">
        <input
            id="showStations"
            onChange={this.toggleStationVisibility}
            checked={this.state.showStations}
            type="checkbox"
        />
        &nbsp;
        <label htmlFor="showStations" className="col-form-label">
          {window.locales.HereMap.toggleStationVisibility}
        </label>
      </Control>
    );
  }

  renderStations() {
    if (!this.state.showStations) return null;

    return this.props.stations.map((station) => {
      const key = station.id;
      return <Station key={key} station={station} />;
    });
  }

  renderRide() {
    if (!this.state.ride || this.state.ride.state === 'searching') return null;

    return <Ride key={this.state.ride.id} ride={this.state.ride} color={colors.black} />;
  }

  renderErrors() {
    if (!this.state.errors || this.state.errors.length === 0) return null;

    return (
      <Alert type="danger">
        <ul>
          {this.state.errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </Alert>
    );
  }

  renderWarnings() {
    if (!this.state.warnings || this.state.warnings.length === 0) return null;

    return (
      <Alert type="warning">
        <ul>
          {this.state.warnings.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </Alert>
    );
  }

  renderPaymentMethodDropdown() {
    if (!this.rideNeedsPaymentMethod()) return null;

    return (
      <PaymentMethodDropdown
        label={window.locales.BookingWizard.paymentMethod}
        paymentMethods={this.state.paymentMethods}
        isDisabled={isEmpty(this.state.userId)}
        handleUpdate={this.setChosenPaymentMethod}
        chosenPaymentMethod={this.state.chosenPaymentMethod}
      />
    );
  }

  renderBookedMessage() {
    if (!this.state.ride || this.state.ride.state !== 'driver_accepted' || !this.state.ride.pickup) {
      return null;
    }

    return (
      <Alert type="success" title={window.locales.BookingWizard.message.title}>
        {window.locales.BookingWizard.message.success}
        <dl className="row">
          <dt className="col-sm-3 text-right">{window.locales.BookingWizard.RideDetails.pickup_time}</dt>
          <dd className="col-sm-9">
            {formattedTime(this.state.ride.pickup.time, this.props.product.timezone.identifier)}
          </dd>

          <dt className="col-sm-3 text-right">{window.locales.BookingWizard.RideDetails.ride_duration}</dt>
          <dd className="col-sm-9">
            {secondsToHuman(rideDuration(this.state.ride))}
            {window.locales.BookingWizard.RideDetails.minutes}
          </dd>

          <dt className="col-sm-3 text-right">{window.locales.BookingWizard.RideDetails.dropoff_time}</dt>
          <dd className="col-sm-9">
            {formattedTime(this.state.ride.dropoff.time, this.props.product.timezone.identifier)}
          </dd>
        </dl>
        <h3>
          {window.locales.BookingWizard.message.booking_code}
          :
          {' '}
          <strong>{this.state.ride.booking.verification_code}</strong>
        </h3>
      </Alert>
    );
  }

  renderAreas() {
    const areas = sortBy(
      compact([
        this.props.product.drt_area,
        this.props.product.intermodal_area,
        ...this.state.servedAreas,
        ...this.state.unservedAreas
      ]), "z_index")

    return areas.map((area) => <Area key={`area-${area.id}`}
                                     area={area.area}
                                     color={area.color}
                                     opacity={area.opacity}
                                     weight={area.stroke_weight}
                                     fillColor={area.fill_color}
                                     fillOpacity={area.fill_opacity} />);
  }

  renderSpinner() {
    if (!this.state.spinnerMessage) return null;

    return (
      <span className="badge badge-secondary">
        <i className="fa fa-spinner fa-pulse fa-fw" />
        <span>{this.state.spinnerMessage}</span>
      </span>
    );
  }

  renderRideInfos() {
    if (
      !this.state.ride ||
      !this.state.ride.pickup ||
      this.state.ride.state === 'searching' ||
      this.state.ride.state === 'cancelled'
    )
      return null;

    return (
      <RideDetails
        ride={this.state.ride}
        requestedTime={this.state.requestedTime}
        timezone={this.props.product.timezone}
        requestedTimeForWaypoint={this.state.requestedTimeForWaypoint}
      />
    );
  }

  renderTimePicker() {
    const { timezone, prebookable, prebooking_threshold: prebookingThreshold  } = this.props.product;
    if (!prebookable) {
      return null;
    }

    return (
      <PrebookingTime
        prebookingThresholdMax={prebookingThreshold.max}
        prebookingThresholdMin={prebookingThreshold.min}
        prebooking={!!this.state.requestedTime}
        handleUpdate={this.setRequestedTime}
        timezone={timezone}
        allowDestinationTime={!!this.props.product.destination_time_based_matching}
        requestedTimeForWaypoint={this.state.requestedTimeForWaypoint}
        requestedTime={this.state.requestedTime}
      />
    );
  }

  renderFixedWaypointSwitcher() {
    if (!this.productRequiresFixedStation()) {
      return null;
    }

    return (
      <ul className="list-group pb-2">
        <li className="list-group-item">
          {window.locales.BookingWizard.fixedStation}
          <span className="float-right">
            <WaypointSwitcher handleUpdate={this.switchFixedWaypoints} waypoint={this.state.fixedWaypoint} />
          </span>
        </li>
      </ul>
    );
  }

  renderPassengerNoteToDriver() {
    if (!this.productSupportsPassengerNoteToDriver()) {
      return null;
     }

    return (
      <PassengerNoteToDriver
        note={this.state.passengerNoteToDriver}
        handleUpdate={this.setPassengerNoteToDriver}
      />
    );
  }

  renderPrebookingShortcutsModal() {
    if (!this.props.product.prebookable) {
      return null;
    }

    return (
      <PreBookingShortcutsModal
        requestedTime={this.state.requestedTime}
        requestedTimeForWaypoint={this.state.requestedTimeForWaypoint}
        prebookingThresholdMin={this.props.product.prebooking_threshold.min}
        prebookingThresholdMax={this.props.product.prebooking_threshold.max}
        timezone={this.props.product.timezone}
        show={this.state.showPrebookingModal}
        toggle={this.togglePrebookingModal}
        setRequestedTime={this.setRequestedTime}
      />
    );
  }

  render() {
    return (
      <div className="booking-wizard">
        <h1>
          {window.locales.BookingWizard.title}
          <span className="float-right">
            {this.renderSpinner()}
            {this.timeLeftToBook()}
          </span>
        </h1>

        <div className="row">
          <div className="col-md-6">
            <UserSelect
              stellwerkAPI={this.api}
              selectedUserId={this.state.userId}
              existingUser={this.props.cloningRide.user}
              handleUpdate={this.setUser}
              provider={this.props.product.provider}
            />
            {this.renderTimePicker()}
            {this.renderFixedWaypointSwitcher()}
            <div className="row">
              <div className="col-md-6">
                <Card title={window.locales.BookingWizard.origin}>
                  <WaypointSelector
                    handleUpdate={this.setOrigin}
                    waypointType="origin"
                    waypoint={this.state.origin}
                    stations={this.props.stations}
                    productRequiresFixedStation={this.productRequiresFixedStation()}
                    fixedStations={this.props.product.fixed_stations}
                    fixedWaypoint={this.state.fixedWaypoint}
                    boundingBox={this.props.product.default_map_bounding_box}
                    previouslyRequestedPoints={this.state.previouslyRequestedPoints}
                    nearbyMatchingMode={this.state.nearbyMatchingMode.origin}
                    handleNearbyMatchingModeUpdate={this.setNearbyMatchingMode}
                  />
                </Card>
              </div>
              <div className="col-md-6">
                <Card title={window.locales.BookingWizard.destination} className="col-md-6">
                  <WaypointSelector
                    handleUpdate={this.setDestination}
                    waypointType="destination"
                    waypoint={this.state.destination}
                    stations={this.props.stations}
                    productRequiresFixedStation={this.productRequiresFixedStation()}
                    fixedStations={this.props.product.fixed_stations}
                    fixedWaypoint={this.state.fixedWaypoint}
                    boundingBox={this.props.product.default_map_bounding_box}
                    previouslyRequestedPoints={this.state.previouslyRequestedPoints}
                    nearbyMatchingMode={this.state.nearbyMatchingMode.destination}
                    handleNearbyMatchingModeUpdate={this.setNearbyMatchingMode}
                  />
                </Card>
              </div>
            </div>

            <RideOptions
              handleOptionUpdate={this.setOption}
              handlePassengerUpdate={this.setPassengers}
              handleAddPassenger={this.addPassenger}
              dynamicRideOptions={this.props.product.product_ride_options}
              options={this.state.options}
              passengers={this.state.passengers}
              passengerOptions={this.props.product.passenger_options}
              passengerTypes={this.props.product.passenger_types}
              nameRequired={this.props.product.requires_passenger_names_or_ticket}

            />
            {this.renderPassengerNoteToDriver()}
          </div>
          <div className="col-md-6">
            {this.renderBookedMessage()}
            {this.renderErrors()}
            {this.renderWarnings()}
            {this.renderPaymentMethodDropdown()}
            {this.renderRideInfos()}
            <div className="actions clearfix">
              <div className="float-left">{this.nextOptionButtons()}</div>
              <div className="float-right">
                {this.createRideButton()}
                {this.bookRideButton()}
                {this.cancelRideButton()}
              </div>
            </div>
            <div className="map-container fullwidth-map pt-3">
              <HereMap boundingBox={this.props.product.default_map_bounding_box}>
                {this.renderAreas()}
                {this.renderRide()}
                {this.renderStationVisibilityToggle()}
                {this.renderStations()}
                {this.destinationMarker()}
                {this.originMarker()}
              </HereMap>
            </div>
          </div>
        </div>
        {this.renderPrebookingShortcutsModal()}
      </div>
    );
  }
}

BookingWizard.defaultProps = {
  cloningRide: {}
};

BookingWizard.propTypes = {
  product: PropTypes.object.isRequired,
  stations: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  cloningRide: PropTypes.object,
  cloningDirection: PropTypes.oneOf(['same', 'return'])
};
